/**
 * @license
 * 
 * SPDX-FileCopyrightText: Copyright © 2021 snek.at
 * SPDX-License-Identifier: EUPL-1.2
 *
 * Use of this source code is governed by an EUPL-1.2 license that can be found
 * in the LICENSE file at https://snek.at/license
 */

@import './colors.scss';
@import './layout.scss';
@import './variables.scss';

body {
    background-color: black !important;
    color: white;
    height: auto !important;

    h1, h2, h3, h4, h5, h6 {
      color: white
    }
  }
  